import React, { useState } from "react"
import { banner, content, banner__container } from "../styles/banner.module.css"
import AppButton from "./AppButton"
import { domains } from "./Join_Pasc_data"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/join_pasc.css"
import Tooltip from "./Tooltip"

const JoinBanner = () => {
  const isBrowser = typeof window !== "undefined"
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
  }

  const [imageIndex, setImageIndex] = useState(0)

  return (
    <div className={` ${banner}`}>
      <div className={`flex justify-between ${banner__container}`}>
        <div className="md:py-8 xs:py-6">
          <div className={`${content} md:mr-20 max-w-3xl`}>
            <div
              className={`md:block xs:hidden heading1 medium mb-6 flex flex-col`}
            >
              <p>Join as Members, </p>
              <p>Leave as Professionals</p>
            </div>
            <p className={`md:hidden heading2 regular mb-6`}>
              <span className="grid">Join as Members, </span>Leave as
              Professionals
            </p>
            <p className={`md:block xs:hidden sub-heading medium`}>
              PASC works in numerous technical and non-technical domains as a
              community. We also host multiple contests, workshops, talks and
              projects in these domains. Join PASC to be a part of this enriching culture & grow together.
            </p>
            <p className={`md:hidden paragraph1 regular`}>
              PASC works in numerous technical and non-technical domains as a
              community. We also host multiple contests, workshops, talks and
              projects in these domains. Join PASC to be a part of this enriching culture & grow together.
            </p>
          </div>
          <div class="TrustBrands_marquee mb-4 lg:mb-0 lg:hidden">
            <span>
              <div
                class="
                    TrustBrands_trustLogos
                    TrustBrands_marqueeAnimate1
                  "
              >
                <div class="TrustBrands_logos">
                  {domains.map(val => (
                    <div class="TrustBrands_trustLogo flex flex-col">
                      <img src={val.image} alt="Trust logo" height="20" />
                      <div className="arrow-up mt-2" />
                      <p className="Paragraph1 Regular p-1 domain-name">
                        {val.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </span>
            <span>
              <div
                class="
                    TrustBrands_trustLogos2
                    TrustBrands_marqueeAnimate2
                  "
              >
                <div class="TrustBrands_logos">
                  {domains.map(val => (
                    <div class="TrustBrands_trustLogo flex flex-col">
                      <img src={val.image} alt="Trust logo" height="20" />
                      <div className="arrow-up mt-2" />
                      <p className="Paragraph1 Regular p-1 domain-name">
                        {val.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </span>
          </div>
            <AppButton
              variant="primary"
              size="small"
              title="JOIN PASC"
              onClick={() => {
                if (isBrowser) {
                  window?.open("https://pict.acm.org/register/")
                }
              }}
            />
        </div>
        <div className=" w-80 lg:flex items-end justify-end  hidden">
          <Slider {...settings} className="pc-slider">
            {domains.map((val, idx) => {
              return (
                <div className="mob-image">
                  <div className="flex flex-row justify-end items-center ">
                    {idx === imageIndex ? (
                      <div className="flex flex-row items-center nameanime">
                        <p className="Paragraph1 Regular py-2 domain-name px-3">
                          {val.name}
                        </p>
                        <div className="arrow-left" />
                      </div>
                    ) : null}
                    <img src={val.image} alt="I" />
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default JoinBanner
